import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, Platform } from '@ionic/angular';
import { BehaviorSubject, filter } from 'rxjs';
import { ChannelFilters, ChannelSortBase, OwnUserResponse, StreamChat, UserResponse } from 'stream-chat';
import { ChannelService, ChatClientService, DefaultStreamChatGenerics, StreamI18nService, NotificationService } from 'stream-chat-angular';
import { UserResponseDto } from '../../../../../aok-backend/src/user/dto/create-user.dto';
import { env } from '../../../environments/environment';
import { NotificationsService } from '../notifications/notifications.service';

@Injectable({
  providedIn: 'root',
})
export class GetStreamService {
  chatIsReady$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private client: StreamChat;

  /** GetStream Channels */
  allChannels: any[] | undefined;
  joinedChannels: any[] | undefined;
  invitedChannels: any[] | undefined;

  /** Create new Channel Users */
  newGroupChatUsers: UserResponseDto[] = [];
  public groupName: string | undefined;

  constructor(
    private router: Router,
    private alertCtrl: AlertController,
    private chatService: ChatClientService,
    private channelService: ChannelService,
    private streamI18nService: StreamI18nService,
    private notificationService: NotificationsService,
    private streamNotificationService: NotificationService,
    private platform: Platform,
    private ngZone: NgZone,
  ) {
    this.client = StreamChat.getInstance(env.stream_api_key);
  }

  async initStream(aokUser: UserResponseDto, authPhoto: string | undefined,  streamIoToken: string) {
    const apiKey = env.stream_api_key;
    const userId = aokUser['id']; // Using the DB ID so as to not share any senetive data outside of our system.
    const userToken = streamIoToken;
    try {
      /**
       * Initialise GetStream Connection
       */
      const streamUser:
        | string
        | OwnUserResponse<DefaultStreamChatGenerics>
        | UserResponse<DefaultStreamChatGenerics>
        | undefined = {
        id: userId,
        name: `${aokUser['displayName']}`,
        profilePhotoUrl: aokUser?.profilePhotoUrl || authPhoto,
        // Add any other user properties you need
      };
      const initiated = await this.chatService.init(apiKey, streamUser, userToken, {
        trackPendingChannelInvites: true
      });

      if (initiated && initiated.me && !initiated.me.banned) {
        this.chatIsReady$.next(true);
        this.loadChannels(aokUser, "all");
      }
      this.streamI18nService.setTranslation();

      if (this.platform.is('capacitor')) {
        this.initNotifications(aokUser);
      }
    } catch (initStreamErr) {
      console.error('initStreamErr: ', initStreamErr);
    }
  }

  async initNotifications(aokUser: UserResponseDto) {
    if (this.platform.is('capacitor')) {
      this.notificationService.initNotifications(aokUser);
    }
  }

  async loadChannels(aokUser: UserResponseDto, whichChannels: "all" | "joined" | "pending") {
    try {
      let filter: ChannelFilters;
      switch (whichChannels) {
        case "all":
          filter = {
            members: { $in: [aokUser.id] },
          };
          break;
        case "joined":
          filter = {
            members: { $in: [aokUser.id] },
            joined: { $eq: true } // Hide Un accpeted invite channels...
          };
          break;
        case "pending":
          filter = {
            members: { $in: [aokUser.id] },
            joined: { $eq: false } // Hide Un accpeted invite channels...
          };
          break;
      }
      const sort: ChannelSortBase = { last_message_at: -1 };

      await this.channelService.init(
        filter,
        sort,
        {
          limit: 25,
          state: true,
        },
        false,
      );

      this.channelService.channels$.subscribe((channels) => {
        if (channels && channels.length > 0) {
          this.allChannels = channels;
          this.invitedChannels = channels.filter(channel => channel?.state?.membership?.status == "pending");
          this.joinedChannels = channels.filter(channel => channel?.state?.membership?.status !== "pending");
        }
      });

      this.listenToEvents();
    } catch (error) {
      console.error('Error querying unread channels:', error);
    }
  }

  listenToEvents() {
    this.chatService.events$
      .pipe(filter((n) => n.eventType === "message.read"))
      .subscribe(() => {
        this.ngZone.run(() => {
          this.router.navigate(['/conversation']);
        });
      });

    this.chatService.events$
      .pipe(filter((n) => n.eventType === "notification.invited"))
      .subscribe((streamEvent) => {
        console.log('🐬🔔🔔 Channel Invite: ', streamEvent)
      });

    this.chatService.events$
      .pipe(filter((n) => n.eventType === "notification.invite_accepted"))
      .subscribe((streamEvent) => {
        console.log('🐬🔔✅✅ Invite Accepted: ', streamEvent)
        this.channelService.reset();
        void this.channelService.init({
          joined: true,
        });
      });
  }

  async createNewGroup() {
    /**
     * 1. Check which users are registered with GetStream.
     * 2. Make a list of registered & non-registered users.
     * 3. Create Channel with Registered users.
     */
    const regUnregUsers: { registered: string[]; unregistered: string[] } = await this.splitRegisteredUsers();

    if (regUnregUsers && regUnregUsers?.unregistered.length > 1) {
      this.unregisteredUsersAlert(regUnregUsers?.unregistered.length)
      return;
    } else {
      try {
        const currentUser = this.chatService.chatClient.userID;
        const newChannel = this.chatService.chatClient.channel('messaging', this.getChannelGuuid(), {
          members: regUnregUsers.registered,
          name: this.groupName || `Group-Chat-${new Date().toISOString()}`,
          createdBy: { id: currentUser },
        });
        await newChannel.create();
        await newChannel.watch();
        this.channelService.addChannel(newChannel);
        this.channelService.setAsActiveChannel(newChannel);
        this.newGroupChatUsers = [];
      } catch (creatChannelError) {
        console.error('creatChannelError: ', creatChannelError);
      }
    }
  }

  getChannelGuuid() {
    const newGuuid = crypto.randomUUID();
    return newGuuid;
  }

  async unregisteredUsersAlert(count: number) {
    const alert = await this.alertCtrl.create({
      header: 'Whoops!',
      subHeader: 'GetStream Error',
      message: `You've tried to start a chat with ${count} unregistered users.`,
      buttons: ['Okay'],
    });

    await alert.present();
  }

  async createGroupFromIDs(userIds: string[]) {
    const channelMembers = this.addMyselfToChannel(userIds);

    try {
      const currentUser = this.chatService.chatClient.userID;
      const newChannel = this.chatService.chatClient.channel('messaging', this.getChannelGuuid(), {
        members: channelMembers,
        name: this.groupName || `Group-Chat-${new Date().toISOString()}`,
        createdBy: { id: currentUser },
        own_capabilities: ['update-channel-members'],
      });
      await newChannel.create();
      await newChannel.watch();
      this.channelService.addChannel(newChannel);
      this.channelService.setAsActiveChannel(newChannel);
      this.newGroupChatUsers = [];
    } catch (creatChannelError) {
      console.error('creatChannelError: ', creatChannelError);
    }
  }

  async addMembersToGroup() {
    try {
      const groupChatUsers = this.newGroupChatUsers.map((user) => user.id);
      await this.channelService.activeChannel?.inviteMembers(groupChatUsers);

      this.newGroupChatUsers = [];
      this.streamNotificationService.addTemporaryNotification(
        "Successfully invited!",
        "success",
      );
    } catch (error) {
      console.error('Error adding member:', error);
    }
  }

  async inviteOnly() {
    try {
      const channelId = this.channelService.activeChannel?.id;
      const channel = this.chatService.chatClient.channel('messaging', channelId);
      const groupChatUsers = this.newGroupChatUsers.map(user => user.id);
      await channel.inviteMembers(groupChatUsers);
      this.newGroupChatUsers = [];
    } catch (error) {
      console.error('Invite only failed:', error);
    }
  }

  async splitRegisteredUsers() {
    const groupChatUsers = this.newGroupChatUsers.map((user) => user.id);
    if (groupChatUsers) {
      const queryUsers = await this.client.queryUsers({ id: { $in: groupChatUsers! } }, { id: 1 });
      console.log('queryUsers: ', queryUsers);
      let registered = queryUsers.users.map((user) => user.id);

      const unregistered = groupChatUsers.filter((userId) => {
        if (!registered.includes(userId)) {
          return userId;
        }
        return;
      });

      /** Check if I am in this list */
      registered = this.addMyselfToChannel(registered);

      return { registered: registered, unregistered: unregistered };
    } else {
      return { registered: [], unregistered: [] };
    }
  }

  addMyselfToChannel(registered: string[]): string[] {
    if (this.client.userID) {
      if (registered.includes(this.client.userID)) {
        return registered;
      } else {
        registered.push(this.client.userID);
        return registered;
      }
    }
    return registered;
  }

  async updateMyStreamProfile(userId: string, userUpdate: Partial<UserResponseDto>) {
    try {
      await this.chatService.chatClient.partialUpdateUser({
        id: userId,
        set: userUpdate
      })
    } catch (updateStreamError) {
      console.warn('updateStreamError: ', updateStreamError)
    }
  }
}
