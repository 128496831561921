import { Injectable } from '@angular/core';
import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { Platform } from '@ionic/angular';
import { firstValueFrom } from 'rxjs';
import { ChatClientService } from 'stream-chat-angular';
import { UserResponseDto } from '../../../../../aok-backend/src/user/dto/create-user.dto';
import { env } from '../../../environments/environment';
import { AokUserService } from '../aok-user/aok-user.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(
    private chatService: ChatClientService,
    private userService: AokUserService,
    private platform: Platform,
  ) {}

  async initNotifications(aokUser: UserResponseDto) {
    this.addTokenReceivedListener(aokUser);
    this.addNotificationReceivedListener();
    this.addNotificationActionPerformedListener();

    const currentPerms = await this.checkPermissions();
    switch (currentPerms) {
      case 'prompt':
        await this.requestPermissions();
        break;
      case 'denied':
        break;
      case 'granted':
        break;
    }
  }

  async addTokenReceivedListener(aokUser: UserResponseDto) {
    await FirebaseMessaging.addListener('tokenReceived', async (event) => {
      /**
       * Save FCM Token to Database User Record.
       */
      await firstValueFrom(this.userService.updateUser(aokUser.id, { fcmToken: event.token }));

      /**
       * Add FCM Token to GetStream for chat notifications.
       */
      this.chatService.chatClient.addDevice(event.token, 'firebase', aokUser.id, env.notification_topic);
    });
  }

  async addNotificationReceivedListener() {
    await FirebaseMessaging.addListener('notificationReceived', (event) => {
      console.log('🔥👂 notificationReceived', { event });
    });
  }

  async addNotificationActionPerformedListener() {
    await FirebaseMessaging.addListener('notificationActionPerformed', (event) => {});
  }

  async checkPermissions() {
    try {
      const result = await FirebaseMessaging.checkPermissions();
      return result.receive;
    } catch (pushPermErr) {
      console.log('🔥🚫 checkPermissions Error: ', pushPermErr);
      return false;
    }
  }

  async requestPermissions() {
    console.log('🔥🔥 requestPermissions()');
    try {
      const result = await FirebaseMessaging.requestPermissions();
      return result.receive;
    } catch (pushPermErr) {
      console.log('🔥🔥🚫 requestPermissions Error: ', pushPermErr);
      return false;
    }
  }
}
